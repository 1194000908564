    import * as Minio from 'minio';

var minioClient = null;

minioClient = new Minio.Client({
    endPoint: 'storage.paas.dbserver.com.br',
    accessKey: 'minio',
    secretKey: 'w5LZZKNBMyAepvih',
    ssl: true
});

export default minioClient;