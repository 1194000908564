import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, FormGroup, Input } from "reactstrap";
import "./login.css";
import logo from "../images/dblab_logo.png";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AccountCircle from '@material-ui/icons/AccountCircle';

const styles = theme => ({
  root: {
    flexGrow: 1
  }
});

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      matricula: ""
    };
  }
  
  handleChange(event) {
    const target = event.target.id;
    const value = event.target.value;
    this.setState({ [target]: value });
  }

  render() {
    const { classes } = this.props;
    var { matricula } = this.state;

    function handleSubmit(event) {
      event.preventDefault();
      var myStorage = window.localStorage;
      myStorage.setItem('matricula', matricula);
      window.location = "/Home";
    }
    return (
      <body className="LoginBody">
        <div className="Login">
          <form onSubmit={handleSubmit}>
            <img src="https://storage.paas.dbserver.com.br/fila-virtual/dblab_logo.png" alt="Logo dblab" />
            <FormGroup controlId="matricula" bsSize="large">
              <Input
                type="text"
                name="matricula"
                id="matricula"
                placeholder="Matrícula"
                value={matricula}
                onChange={this.handleChange.bind(this)}
              />
            </FormGroup>
            <Button block color="primary" bsSize="large" type="submit">
              Log in
            </Button>
          </form>
        </div>
      </body>
    );
  }
}
Login.propTypes = {
  classes: PropTypes.object.isRequired
};
export default Login;
