import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Header from "./Header/index";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Accessible, ForumRounded, TimerRounded } from "@material-ui/icons";
import "./home.css";
import { Container } from "@material-ui/core";
import { FaUserClock } from "react-icons/fa";
import { AiFillClockCircle } from "react-icons/ai";
import { IoIosChatbubbles } from "react-icons/io";
import priorityIcon from "../images/priority_white.svg";
import wcIcon from "../images/wc_icon.svg";
import Minio from "../services/minio";
import axios from "axios";
import Websocket from "react-websocket";
import io from "socket.io-client"

const baseURL = "https://fila-virtual-back.paas.dbserver.com.br/";
const options = {
  headers: { "Access-Control-Allow-Origin": "*" }
};

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  cardAttend: {
    display: "flex",
    width: 410,
    backgroundColor: "#EDF1FA",
    borderRadius: 20,
    height: 130,
    borderColor: "#344A94"
  },
  cardWaitAttend: {
    display: "flex",
    width: 410,
    height: 130,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    borderWidth: 2,
    borderColor: "#1F9752",
    borderStyle: "dashed"
  },
  photo: {
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    width: 90,
    height: 90,
    borderRadius: 50
  },
  nameFont: {
    fontSize: 16,
    //fontFamily: 'Rawline',
    fontWeight: "fontWeightRegular",
    variant: "h3",
    color: "#1E1C61"
  },
  sub: {
    fontSize: 14,
    //fontFamily: 'Rawline',
    fontWeight: "fontWeightMedium",
    color: "#1E1C61",
    opacity: "65%"
  },

  pos: {
    fontSize: 18,
    fontWeight: "fontWeightMedium",
    color: "#FFFFFF"
  },

  cancel: {
    backgroundColor: "#F9FAFB",
    width: 100,
    height: 30,
    opacity: "90%",
    marginTop: 10,
    marginRight: 10,
    textTransform: "none",
    color: "#757575"
  },

  button: {
    backgroundColor: "#344A94",
    width: 100,
    height: 30,
    opacity: "90%",
    textTransform: "none",
    marginTop: 10,
    color: "#FFFFFF"
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  cover: {
    width: 55,
    display: "flex",
    alignItems: "center"
  },
  title: {
    color: "#344A94",
    variant: "h2",
    fontSize: 18
  },
  abstract: {
    width: 420,
    height: 50,
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    fontSize: 14,
    //fontFamily: 'Rawline',
    fontWeight: "fontWeightMedium",
    color: "#000000"
  },

  abstractText: {
    color: "#757575",
    fontSize: 14,
    fontWeight: "fontWeightMedium"
  },

  orangeText: {
    color: "#EB6023",
    fontSize: 16
  },
  blueText: {
    color: "#344A94"
  },
  wcAbstractIcon: {
    height: 16,
    width: 16
  },
  priorityAbstractIcon: {
    filter:
      "invert(48%) sepia(22%) saturate(18%) hue-rotate(31deg) brightness(91%) contrast(89%);",
    height: 16,
    width: 16
  }
});

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      matricula: null,
      esperandoAtendimento: [],
      emAtendimento: [],
      socket: null
    };
  }

  componentDidMount() {
    var myStorage = window.localStorage;
    var matriculaStorage = myStorage.getItem("matricula");
    
    // this.setState({socket: io.connect('http://' + '0.0.0.0' + ':8080/socketio/')});
    // this.state.socket.on('connect', function(data) {console.log('Connected to socket')});

    const socket = io.connect(baseURL);
    if(socket != null) {
      console.log("socketio connected")
    }

    socket.on('newClient', data => {
      this.setState({ esperandoAtendimento: this.state.esperandoAtendimento.concat(data) })
    });

    socket.on('inAttendance', data => {
      this.setState({
        emAtendimento: this.state.emAtendimento.concat(data),
        esperandoAtendimento: this.state.esperandoAtendimento.filter(item => item.client !== data.client)
       });
    });

    socket.on('Attended', data => {
      this.setState({
        emAtendimento: this.state.emAtendimento.filter(item => item.client !== data.client)
       });
    });

    socket.on('Cancelled', data => {
      this.setState({
        esperandoAtendimento: this.state.esperandoAtendimento.filter(item => item.client !== data.client)
       });
    });

    console.log(matriculaStorage);
    if (matriculaStorage == null) {
      window.location = "/";
    } else {
      this.setState({
        matricula: matriculaStorage
      });
    }

    this.callFilas();
    // Minio.getObject('fila-virtual', 'dblab-logo.png', function(e, dataStream) {
    //   if (e) {
    //     return console.log(e)
    //   }
    //   dataStream.on('data', function(chunk) {
    //     console.log(chunk);
    //   })
    //   dataStream.on('end', function() {
    //     console.log("End")
    //   })
    //   dataStream.on('error', function(e) {
    //     console.log(e)
    //   })
    // })
  }

  callFilas() {
    axios
      .post(baseURL + `filas`)
      .then(res => {
        const response = res.data;
        console.log(response);
        this.setState({ esperandoAtendimento: response.WaitingService });
        this.setState({ emAtendimento: response.InService });
      })
      .catch(e => {
        console.log(e);
        return e;
      });
  }

  callEmAtendimento(clientID) {
    axios
      .post(baseURL + `em-atendimento/` + clientID, {
        clerkID: window.localStorage.getItem("matricula")
      })
      .then(res => {
        const response = res.data;
        console.log(response);
      })
      .catch(e => {
        console.log(e);
        return e;
      });
  }

  callCancelaEspera(clientID) {
    axios
      .post(baseURL + `cancela-espera/` + clientID, {
        clerkID: window.localStorage.getItem("matricula")
      })
      .then(res => {
        const response = res.data;
        console.log(response);
      })
      .catch(e => {
        console.log(e);
        return e;
      });
  }

  callAtendido(clientID) {
    axios
      .post(baseURL + `atendido/` + clientID, {
        clerkID: window.localStorage.getItem("matricula")
      })
      .then(res => {
        const response = res.data;
        console.log(response);
      })
      .catch(e => {
        console.log(e);
        return e;
      });
  }

  handleData(data) {
    let result = JSON.parse(data);
    console.log(result);
    //Todo
  }

  render() {
    const { classes } = this.props;
    var { emAtendimento, esperandoAtendimento, matricula } = this.state;
    return (
      <React.Fragment>
        {matricula == null ? (
          <p>Erro 404</p>
        ) : (
          <main>
            {/* <Websocket
              url="ws://fila-virtual-back-dblab.apps.ocp.dbserver.com.br/"
              onMessage={this.handleData.bind(this)}
            /> */}
            <Header matricula={matricula} />
            <Container className={classes.cardGrid} maxWidth="lg">
              <Grid container spacing={4}>
                <Grid item key="AguardAtend" xs={12} sm={12} md={6}>
                  <Card className={classes.abstract}>
                    <CardContent>
                      <Typography className={classes.abstractText}>
                        Aguardando Atendimento:{" "}
                        <b className={classes.orangeText}>
                          {
                            esperandoAtendimento.filter(function(cliente) {
                              return cliente.disability === 0;
                            }).length
                          }
                        </b>
                        <img
                          src={wcIcon}
                          className={classes.wcAbstractIcon}
                          alt="WC"
                        ></img>
                        <b className={classes.blueText}> |</b>{" "}
                        <b className={classes.orangeText}>
                          {
                            esperandoAtendimento.filter(function(cliente) {
                              return cliente.disability === 1;
                            }).length
                          }
                        </b>
                        <img
                          src={priorityIcon}
                          alt="prioritário"
                          className={classes.priorityAbstractIcon}
                        ></img>
                        <b className={classes.blueText}> |</b>{" "}
                        <b className={classes.orangeText}>
                          {esperandoAtendimento.length}
                        </b>{" "}
                        Total
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item key="tempMedio" xs={12} sm={12} md={6}>
                  <Card className={classes.abstract}>
                    <CardContent>
                      <Typography className={classes.abstractText}>
                        Tempo Médio de Atendimento:{" "}
                        <b className={classes.orangeText}>00:30</b> minutos{" "}
                        <AiFillClockCircle />
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <Typography className={classes.title}>
                    <IoIosChatbubbles style={{ marginRight: 10 }} />
                    Clientes em Atendimento
                  </Typography>
                </Grid>

                {emAtendimento.map(clientes => (
                  <Grid item key={clientes} xs={12} sm={12} md={6} lg={4}>
                    <Card className={classes.cardAttend} variant="outlined">
                      <CardContent>
                        <Grid container spacing={10}>
                          <Grid item xs={3}>
                            <img
                              className={classes.photo}
                              src={clientes.pictureLink}
                              alt="Logo dblab"
                            />
                          </Grid>

                          <Grid item xs={9} style={{ paddingLeft: 50 }}>
                            <Typography className={classes.nameFont}>
                              {clientes.client}
                            </Typography>
                            <Typography className={classes.sub}>
                              Entrada: {clientes.arrivalTime}
                            </Typography>

                            <Button
                              variant="contained"
                              className={classes.button}
                              href="#outlined-buttons"
                              onClick={() => this.callAtendido(clientes.client)}
                            >
                              Finalizar
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardContent className={classes.cover}>
                        <Grid item xs={1}>
                          {clientes.disability === 1 && (
                            <img
                              src={priorityIcon}
                              alt="Prioritário"
                              className={classes.priorityAbstractIcon}
                            ></img>
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}

                <Grid item xs={12}>
                  <Typography className={classes.title}>
                    {" "}
                    <FaUserClock style={{ marginRight: 10 }} /> Clientes
                    Aguardando Atendimento
                  </Typography>
                </Grid>

                {esperandoAtendimento.map((clientes, index) => (
                  <Grid item key={clientes} xs={12} sm={12} md={6} lg={4}>
                    <Card className={classes.cardWaitAttend} variant="outlined">
                      <CardContent style={{ width: 380 }}>
                        <Grid container spacing={10}>
                          <Grid item xs={3}>
                            <img
                              className={classes.photo}
                              src={clientes.pictureLink}
                              alt="Logo dblab"
                            />
                          </Grid>

                          <Grid item xs={9} style={{ paddingLeft: 45 }}>
                            <Typography className={classes.nameFont}>
                              {clientes.client}
                            </Typography>
                            <Typography className={classes.sub}>
                              Entrada: {clientes.arrivalTime}
                            </Typography>

                            <Button
                              variant="contained"
                              className={classes.cancel}
                              href="#outlined-buttons"
                              onClick={() =>
                                this.callCancelaEspera(clientes.client)
                              }
                            >
                              Cancelar
                            </Button>
                            <Button
                              variant="contained"
                              className={classes.button}
                              href="#outlined-buttons"
                              onClick={() =>
                                this.callEmAtendimento(clientes.client)
                              }
                            >
                              Iniciar
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>

                      <CardContent
                        style={{ backgroundColor: "#1F9752" }}
                        className={classes.cover}
                      >
                        <Grid item xs={1}>
                          <Typography className={classes.pos}>
                            {index + 1}º
                          </Typography>
                          {clientes.disability === 1 && (
                            <img src={priorityIcon} alt="Prioritário"></img>
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </main>
        )}
      </React.Fragment>
    );
  }
}
Home.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Home);
