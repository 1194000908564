import React from 'react';
import './styles.css';
import { Container, Grid } from "@material-ui/core";
import { IoMdExit } from "react-icons/io";

const exitURL = './Images/exit.png';
const user = {
    'Matrícula': '12202428'
}

function Logout(){
    var myStorage = window.localStorage;
    myStorage.clear();
    window.location = "/";
}

function Header(props) {
    return (
            <div className='header'>
                <img className='logo' src="https://storage.paas.dbserver.com.br/fila-virtual/logo.png" alt='LogoDBLab'/>
                <div className="matricula">Matrícula: {props.matricula}</div>
                <div className='exit' onClick={() => Logout()}>
                    Sair
                </div>
                <div className='iconExit' onClick={() => Logout()}>
                <IoMdExit/>
                </div>
            </div>
            )}
export default Header;